<template>
  <div>
    <CatalogoHeader />
    <v-row>
      <v-col xs="12" sm="4" md="4" lg="3" xl="2">
        <CatalogoFiltro />
      </v-col>
      <v-col xs="12" sm="8" md="8" lg="9" xl="10">
        <CatalogoListadoProductos />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CatalogoFiltro from "./catalogo/CatalogoFiltroComponent.vue";
import CatalogoListadoProductos from "./catalogo/CatalogoListadoProductosComponent.vue";
import CatalogoHeader from "./catalogo/CatalogoHeaderComponent.vue";
export default {
  name: "CatalogoComprasComponent",
  components: {
    CatalogoFiltro,
    CatalogoListadoProductos,
    CatalogoHeader,
  },
  methods: {
    ...mapActions("catalogo", ["obtenerProductos"]),
  },

  mounted() {
   // this.obtenerProductos();     se comento esta linea para solventar una observacion QA 2.1 sprint 6"No se debe cargar la lista de productos al entrar a la pantalla"
  },
};
</script>
<style>
.theme--light.v-application .text--secondary{
  color: #404A7A !important;
}
</style>