<template>
  <div>
    <p class="text-h6 text--secondary" id="titulo">Catálogo de compras</p>
    <v-row>
      <v-col cols="6">
        <v-autocomplete
          item-text="nombre_obs"
          item-value="id_obs"
          outlined
          label="OBS"
          :items="obs"
          clearable
          @change="updateObsFilter"
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          item-text="nombre"
          item-value="id"
          outlined
          multiple
          label="Proveedor"
          :items="proveedores"
          clearable
          @change="updateProviderFilter"
        />
      </v-col>

    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "CatalogoHeaderComponent",
  data() {
    return {
      obs: [],
    };
  },
  computed: {
    ...mapState("catalogo", ["productosFilters", "proveedores"]),
  },
  methods: {
    ...mapMutations("catalogo", [
      "setProveedorFilter",
      "setObsFilter",
      "setProvidersList",
    ]),
    ...mapActions("catalogo", ["obtenerProductos"]),
    async getProvidersList() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesProveedores({
          id_obs: this.productosFilters.id_obs
        });

      if (status === 200) {
        this.setProvidersList(data);
      }
    },
    async getObs() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudesObs({
          id_proveedor: this.productosFilters.id_proveedor,
        });
      if (status === 200) {
        this.obs = data;
      }
    },
    updateProviderFilter(val) {
      this.setProveedorFilter(val);
      this.obtenerProductos();
      this.getObs();
    },
    updateObsFilter(val) {
      this.setObsFilter(val);
      this.obtenerProductos();
      this.getProvidersList();
    },
  },
  created() {
    this.getProvidersList();
    this.getObs();
  },
};
</script>
