<template>
  <section>
    <section v-if="loading" class="d-flex justify-center mt-15">
      <v-progress-circular indeterminate color="primary" />
    </section>
    <v-row v-else>
      <v-col
        v-for="producto in productos"
        :key="producto.id"
        xs="12"
        sm="6"
        md="4"
        xl="3"
      >
        <section @click="verProducto(producto)" >
          <CatalogoCardComponent :producto="producto"/>
        </section>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import CatalogoCardComponent from "./CatalogoCardComponent.vue"
export default {
  name: "CatalogoListadoProductosComponent",
  components:{
    CatalogoCardComponent
  },
  computed: {
    ...mapState("catalogo", ["productos", "loading"]),
  },
  methods: {
    verProducto(producto) {
       this.$router.push({
         name: "catalogo-de-compras-producto",
         params: { id: producto.id_presentacion_obs_proveedor
 },
       });
    },
  },
  watch:{
    productos (val) {
     
      if(this.productos.length == 0){
        this.temporalAlert({
          show: true,
          message: "No se encontraron productos",
          type: 'error'
        })
      }
    }
  }
};
</script>


