<template>
  <section>
    <p>Precio</p>
    <v-row>
      <v-col cols="6">
        <v-text-field
          @click:clear="setPrecioMinFilter(null), obtenerProductos()"
          v-mask="currencyMask"
          @keyup="filterPrice"
          label="Desde"
          v-model="productosFilters.precio_minimo"
          prepend-inner-icon="mdi-currency-usd"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          @click:clear="setPrecioMaxFilter(null), obtenerProductos()"
          v-mask="currencyMask"
          @keyup="filterPrice"
          label="Hasta"
          prepend-inner-icon="mdi-currency-usd"
          v-model="productosFilters.precio_maximo"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default {
  name: "CatalogoFiltroComponent",
  data: () => ({
    timer: null,
    precio_minimo: null,
    precio_maximo: null,
  }),
  computed: {
    ...mapState("catalogo", ["proveedores", "productosFilters"]),
    currencyMask() {
      return createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      });
    },
  },
  methods: {
    ...mapActions("catalogo", ["obtenerProductos"]),
    ...mapMutations("catalogo", ["setPrecioMinFilter", "setPrecioMaxFilter"]),
    filterPrice() {
      if (
        this.productosFilters.precio_maximo !== "" ||
        this.productosFilters.precio_minimo !== ""
      ) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(async () => {
          await this.obtenerProductos();
        }, 500);
      }
    },
  },
  created() {
    this.productosFilters.precio_minimo = "";
    this.productosFilters.precio_maximo = "";
  },
};
</script>

<style scoped lang="scss">
ul {
  margin: 0px;
  padding: 0px;
}
ul,
li {
  list-style-type: none;
  cursor: pointer;
  margin: 5px;
}

.item-activo {
  color: #313945;
}

.item {
  color: rgba(#313945, 0.7);
}
</style>
