<template>
  <div class="producto-card">
    <v-row>
      <v-img
        @error="imgError = true"
        v-if="!imgError"
        min-height="250"
        max-height="250"
        contain
        class="imagen-producto"
        :src="image"
      />
      <v-icon v-else size="250"> mdi-image-outline </v-icon>
    </v-row>
    <v-row>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Proveedor:</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-subtitulo primary--text">
        <p>{{ producto?.nombre_comercial }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>OBS:</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-subtitulo primary--text">
        <p>{{ producto.nombre_obs }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Unidad de medida:</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-subtitulo primary--text">
        <p>{{ producto.unidad }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Presentación:</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-subtitulo primary--text">
        <p>{{ producto.nombre_presentacion }}</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-titulo">
        <p>Precio:</p>
      </v-col>
      <v-col cols="12" class="caracteristica-producto-subtitulo primary--text">
        <p>
          {{
            Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(producto.precio_unitario)
          }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "CatalogoCardComponent",
  props: {
    producto: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    image: null,
    imgError: false,
  }),
  methods: {
    async getPresentationImage() {
      const { data } =
        await this.services.AgreementMarco.getImagenesPresentacion(
          this.producto.obs_foto[0]?.id
        );
      this.image = await this.readFileAsync(data);
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
  },
  created() {
    this.getPresentationImage();
  },
};
</script>
<style scoped lang="scss">
.producto-card {
  cursor: pointer;
  padding: 12px 25px 25px 25px;
  // margin: 12px;
  .imagen-producto {
    margin: 20px 0px 20px 0px;
  }
  .caracteristica-producto-titulo,
  .caracteristica-producto-subtitulo {
    padding: 0px 12px 0px 12px;
    margin: 0px;
    p {
      margin: 0px 0px 5px 0px;
    }
  }
  .caracteristica-producto-titulo p {
    color: #697185;
  }
  &:hover {
    background: #404a7a;
    .caracteristica-producto-subtitulo p {
      color: #f4f7ff;
    }
  }
}
</style>
